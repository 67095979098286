import * as React from "react"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
// import Seo from "../components/Seo"
import Seo2 from "../components/Seo2"
import SignupForm from "../components/newsletter/SignupForm"
import { PageTitle } from "../components/PageTitles"


const NewsletterPage = () => {

  return (
    <Layout>
      {/* <Seo title="Newsletter" description={`Stay posted on all things Lighter Than Air by subscribing to our newsletter.`} /> */}
        <PageWrapper>
          <PageTitle>Newsletter</PageTitle>
          <SignupForm className={`pt-4 pb-4`} showHeader={false} />
  
        </PageWrapper>
    </Layout>
  )
}

export const Head = () => <Seo2 title="Newsletter" description={`Stay posted on all things Lighter Than Air by subscribing to our newsletter.`} />

export default NewsletterPage
